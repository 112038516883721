@import '../../theme/core.scss';

.root {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $dark-gray;
  height: '100vh';
}

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: rem(50);
}

.middlContainer {
  background-color: white;
  // width: 40%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sideContainer {
  flex: 1;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}



.gridContainerB {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  // background-color: #2196F3;
  // height: 1px;
  // padding: 10px;
}

.gridItem {
  // background-color: rgba(255, 255, 255, 0.8);
  // border: 1px solid rgba(0, 0, 0, 0.8);
  // padding: 20px;
  // font-size: 30px;
  text-align: center;
  display: flex;
  flex: 1;
  // height: 53.67px;
}

.topRow {
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.middleRow {
  // background-color: black;
}

.totalLast {
  font-family: 'BebasNeue';
  color: white;
  font-size: 2vh;
  text-shadow: 1px 1px 4px #000000;
}

.gameName {
  text-shadow: 1px 1px 4px #000000;
  background-color: #00000050;
  color: white;
  // margin-top: 2%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
  font-family: 'BebasNeue';
}

