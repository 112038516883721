@import '../../theme/core.scss';

.wrapper {
    // background-color: red;
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // z-index: 10000;
    // display: flex;
    // flex-direction: column;
    // background-color: black;
    // background-image: url(../../assets/images/imageBackground.jpg);

    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: 85%;
    border-radius: 32px 0px 0px 32px;
    aspect-ratio: 8/16;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    // background-color: #2196F3;
    // height: 1px;
    // padding: 10px;
}

.gridContainerNumbers {
    flex: 1;
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
    grid-template-rows: repeat(15, auto);
    grid-auto-flow: column;
    list-style: none;
    grid-gap: 4px;
    padding: 4px // background-color: #2196F3;
        // height: 1px;
        // padding: 10px;
}

.gridItem {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-family: 'BebasNeue';
    font-size: 2.8vh;
}

.animate_wrapper {
    position: absolute !important;
    z-index: 100000 !important;
    width: 100% !important;
    height: 100% !important;
}