/**
 * ----------------------------------------
 * Mixins
 * ----------------------------------------
 */

@mixin button-fill($color) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $color;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.4);
  border-radius: rem(3);
  padding: rem(4) rem(16);
  &:hover {
    background: lighten($color, 10%);
    transition: all 0.3s ease;
  }
  &:active {
    background: lighten($color, 10%);
  }
}

@mixin button-outline($color) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid $color;
  background-color: white;
  color: $color;
  border-radius: rem(3);
  padding: rem(4) rem(16);
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  &:hover {
    color: white;
    background: lighten($color, 10%);
    transition: all 0.3s ease;
  }
  &:active {
    color: white;
    background: lighten($color, 10%);
  }
}
