@import '../../../theme/core.scss';

.root {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $dark-gray;
  height: '100vh';
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middleContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sideContainer {
  flex: 1;
}

.menuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}

.topRow {
}

.middleRow {
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fee400;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edd500;
}

.frameText {
  align-self: center;
  color: #fff;
  font-family: 'BebasNeue';
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageTitle {
  font-family: 'GamerStation';
  color: $yellow;
  -webkit-text-stroke: 2px purple;
  font-size: 5vh;
  text-align: center;
}

.description {
  color: #fff;
  text-align: center;
  font-size: 16px;
}
