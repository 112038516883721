@import '../../theme/core.scss';

.root {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $dark-gray;
  height: '100vh';
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middleContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sideContainer {
  flex: 1;
}

.menuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  z-index: 10;
}

.topRow {}

.middleRow {}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fee400;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edd500;
}

.dropDown>div {
  width: 100%;
  background: #fff;
  border-radius: 50px;
}

.dropDown>div>div {
  border: none;
  padding: 0.375rem 0.75rem;
  height: 45px;
}