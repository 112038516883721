@import '../../theme/core.scss';

.wrapper {
    // background-color: red;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
    display: flex;
    flex-direction: column;

    // background-image: url(../../assets/images/imageBackground.jpg);
}