@import '../../theme/core.scss';

.input-container {
  position: relative;
  // margin-bottom: rem(8);
}

.input-label {
  font-weight: bold;
  font-size: rem(14);
  margin-bottom: rem(4);
  color: white;
}

.input {
  border-radius: 50px !important;
  font-size: rem(16);
  padding-left: 30px;
  height: unset !important;
  // background-color: #9d7fe3 !important;
  color: #fff;

  &::placeholder {
    text-overflow: ellipsis !important;
    font-size: rem(14);
  }
}

.input-icon {
  position: absolute;
  top: 8px;
  left: 10px;
}