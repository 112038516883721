@import '../../theme/core.scss';

.wrapper {
  // background-color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  display: flex;
  flex-direction: column;

  // background-image: url(../../assets/images/imageBackground.jpg);
}

.chatContainer {
  // flex: 1;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 85%;
  border-radius: 0 32px 32px 0;
  aspect-ratio: 8/16;
}

.chatBubbleRight,
.chatBubbleLeft {
  position: relative;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  /* width: 300px; */
  // background: #fff;
  border-radius: 12px;
  padding: 3px;
  /* text-align: center; */
  color: #000;
  margin: 16px 0;
  min-width: 25%;
  max-width: 80%;
  background-image: linear-gradient(#ffe500, #ff7a01);
}

.chatBubbleRight::before {
  content: '';
  position: absolute;
  border-right: 14px solid #ffffff;
  border-bottom: 19px solid transparent;
  bottom: -12px;
  z-index: 1;
}

.chatBubbleRight::after {
  content: '';
  position: absolute;
  border-right: 16px solid #ff7a01;
  border-bottom: 21px solid transparent;
  bottom: -21px;
}

.chatBubbleLeft::before {
  content: '';
  position: absolute;
  border-left: 14px solid #ffffff;
  border-bottom: 19px solid transparent;
  bottom: -12px;
  z-index: 1;
}

.chatBubbleLeft::after {
  content: '';
  position: absolute;
  border-left: 17px solid #ff7a01;
  border-bottom: 21px solid transparent;
  bottom: -21px;
}

.chatBubbleLeft::after {
  left: 13px;
}

.chatBubbleRight::after {
  right: 13px;
}

.chatBubbleRight::before {
  right: 16px;
}

.chatBubbleLeft::before {
  left: 16px;
}

.chatBubbleRight {
  align-self: flex-end;
}

.chatBubbleLeft {
  align-self: flex-start;
}

.right,
.left {
  background: #fff;
  width: 100%;
  border-radius: 9px;
  padding: 10px;
}