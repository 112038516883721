@import '../../theme/core.scss';

.button_ {
  -webkit-appearance: none !important;
  appearance: none !important;
  position: relative !important;
  border-width: 0 !important;
  padding: 0 8px 12px !important;
  // min-width: 10em !important;
  width: 85%;
  box-sizing: border-box !important;
  background: transparent !important;
  font: inherit !important;
  cursor: pointer !important;

}

.buttonTop {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  z-index: 2 !important;
  padding: 8px 8px !important;
  transform: translateY(0) !important;
  text-align: center !important;
  color: #fff !important;
  text-shadow: 0 -1px rgba(0, 0, 0, .25) !important;
  transition-property: transform !important;
  transition-duration: .2s !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  aspect-ratio: 1;
}

.button_:active .buttonTop {
  transform: translateY(6px) !important;
}

.buttonTop::after {
  content: '' !important;
  position: absolute !important;
  z-index: 1 !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: 100% !important;
  box-sizing: content-box !important;
  background-image: url(../../assets/images/Sunburst.png), radial-gradient(#ffe500, #ff7b01) !important;
  text-align: center !important;
  color: #fff !important;
  box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, .2), 0 1px 2px 1px rgba(255, 255, 255, .2) !important;
  transition-property: border-radius, padding, width, transform !important;
  transition-duration: .2s !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.button_:active .buttonTop::after {
  border-radius: 6px !important;
  padding: 0 2px !important;
}

.buttonBottom {
  position: absolute !important;
  z-index: 1 !important;
  bottom: 4px !important;
  left: 4px !important;
  border-radius: 8px / 16px 16px 8px 8px !important;
  padding-top: 6px !important;
  width: calc(100% - 8px) !important;
  height: calc(100% - 10px) !important;
  box-sizing: content-box !important;
  background-color: #ff7b01 !important;
  background-image: radial-gradient(4px 8px at 4px calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(4px 8px at calc(100% - 4px) calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(16px at -4px 0, white, transparent), radial-gradient(16px at calc(100% + 4px) 0, white, transparent) !important;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5), inset 0 -1px 3px 3px rgba(0, 0, 0, .4) !important;
  transition-property: border-radius, padding-top !important;
  transition-duration: .2s !important;
}

.button_:active .buttonBottom {
  border-radius: 10px 10px 8px 8px / 8px !important;
  padding-top: 0 !important;
}

.buttonBase {
  position: absolute !important;
  z-index: 0 !important;
  top: 4px !important;
  left: 0 !important;
  border-radius: 12px !important;
  width: 100% !important;
  height: calc(100% - 4px) !important;
  background-color: #ff7b01 !important;
  box-shadow: 0 1px 1px 0 black, inset 0 2px 2px rgba(0, 0, 0, .25) !important;
}

.buttonText {
  text-shadow: 1px 1px 4px #000000;
  text-align: center;
}