@import '../../theme/core.scss';

.title {
  color: #fff;
  font-family: 'GamerStation';
  font-size: 3vh;
  margin-left: 20px;
}

.btnAction {
  background-position: center !important;
  background-size: cover !important;
}
