@import '../../theme/core.scss';

.root {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $dark-gray;
  height: '100vh';
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.greeting {
  color: white;
  font-size: rem(16);
  position: absolute;
  top: rem(32);
  right: rem(32);
}

.title {
  color: white;
  margin-top: rem(40);
  font-size: rem(52);
  text-align: center;
}

.description {
  color: $light-gray;
  margin-top: rem(24);
  font-size: rem(16);
  text-align: center;
}

.logo {
  width: rem(160);
  height: rem(160);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  max-width: rem(480);
  margin-top: rem(120);

  @include phone {
    width: 100%;
    max-width: 100%;
    margin: rem(120) rem(16);
  }
}

.download {
  width: 45%;
  height: rem(50);
}

.logout {
  width: 45%;
  height: rem(50);
}

.middleContainer {
  // background-color: white;
  // width: 40%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sideContainer {
  flex: 1;
}

.topRow {
  // flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  // align-items: center;
}

.logoOld {
  background-image: url(../../assets/images/logoold.png);
  background-size: contain;
  flex: 1;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 5%;
  margin-right: 5%;
}

.width20 {
  width: 20%;
}

.whiteLetters {
  color: #fff;
  font-size: 2vh;
  margin-bottom: 16px;
}

.middleRow {
  flex: 1;
  overflow-y: auto;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fee400;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edd500;
}

// modal css
.modalTitle {
  font-family: 'GamerStation';
  color: $yellow;
  -webkit-text-stroke: 1px purple;
  margin-bottom: 16px;
  font-size: 4vh;
}

.radioBtns {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  font-family: 'BebasNeue';
  letter-spacing: 1px;

  input {
    margin-right: 2px;
  }
}

.inputClass {
  input {
    border-radius: 50px;
  }
}

.btnComponent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card {
  background-color: #fff;
  padding: 16px;
  width: 100%;
  // height: 200px;
  border-radius: 15px;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardTitle {
  font-family: 'GamerStation';
  color: $yellow;
  -webkit-text-stroke: 2px purple;
  font-size: 4vh;
  text-align: center;
}

.cardDescription {
  font-family: 'BebasNeue';
  color: $orange;
  text-align: center;
}