@import '../../theme/core.scss';

.locationCard {
  background-color: #fff;
  padding: 16px;
  width: 100%;
  // height: 200px;
  border-radius: 28px;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftComponent {
  flex: 2;
}

.rightComponent {
  text-align: center;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.locationName {
  font-family: 'GamerStation';
  font-size: 3vh;
  color: #953737;
  // margin-bottom: 8px;
}

.capitalLetters {
  text-transform: uppercase;
  letter-spacing: 0.01px;
  font-size: '2vh';
  font-family: 'BebasNeue';
  color: #7f4799;
}

// .faGradient {
//   background: -webkit-gradient(
//     linear,
//     left top,
//     left bottom,
//     from(#f00),
//     to(#333)
//   );
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// }