@import '../../theme/core.scss';

.wrapper {

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-width: 2.5px;
    background-color: #ffffff99;
    border-color: white;
    border-style: solid;
    border-radius: 8px;
    aspect-ratio: 1
}