@import '../../theme/core.scss';

.imgButtonImg:active {

  scale: 0.95;
  ;
}

.buttonText {
  text-shadow: 1px 1px 4px #000000
}