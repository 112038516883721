@import '../../theme/core.scss';

.locationCard {
  background-color: #fff;
  padding: 16px;
  width: 100%;
  // height: 200px;
  border-radius: 28px;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftComponent {
  flex: 2;
}

.rightComponent {
  text-align: center;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.locationName {
  font-family: 'GamerStation';
  font-size: 3vh;
  color: #953737;
  margin-bottom: 8px;
}

.capitalLetters {
  text-transform: uppercase;
  letter-spacing: 0.01px;
  font-size: '2vh';
  font-family: 'BebasNeue';
  color: #7f4799;
}

.grayText {
  color: #953737;
  font-size: 10px;
  // margin-top: 4px;
  line-height: 1;
}
.disabledText {
  color: gray;
  font-size: 10px;
  // margin-top: 4px;
  line-height: 1;
}
.rightSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.coupen {
  display: flex;
  // height: 50px;
  width: 100%;
  background-color: white;
  margin-bottom: 16px;

  -webkit-mask-image: radial-gradient(
      circle at 20% 6px,
      transparent 6px,
      red 6.5px
    ),
    radial-gradient(closest-side circle at 50%, red 99%, transparent 100%);
  -webkit-mask-size: 100%, 3px 12px;
  -webkit-mask-repeat: repeat, repeat-y;
  -webkit-mask-position: 0 -6px, calc(20% - 1px);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;

  // -webkit-mask-position: 0 -10px, calc(20% - 1px);
  border-radius: 6px;
  gap: 14px;
}
.coupen div {
}
.coupenleft {
  // background-color: #7f4799;
  width: calc(20% - 7px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
}
.coupenright {
  // background-color: transparent;
  flex: 1;
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.shadow {
  width: 100%;
  height: calc(100% - 7px);
  position: absolute;
  -webkit-box-shadow: 4px 4px 18px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 18px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 4px 18px -10px rgba(0, 0, 0, 0.75);
}
