/**
 * ----------------------------------------
 * Variables
 * ----------------------------------------
 */

// pink
$pink: #ff3b86;
$light-pink: #fcb8d2;

// purple
$purple: #9d7fe3;
$light-purple: #c3b6e2;

// gray
$gray: #cacaca;
$dark-gray: #333a41;
$light-gray: #1e232b;

// blue
$light-blue: #d2e2fa;

//red
$red: #ea0a0a;
$light-red: #d54e4e;

//green
$green: #089213;
$light-green: #38ea2b;

// gradation
$gradient-pink-purple: linear-gradient(125.07deg, $pink 0%, $purple 66.18%);

// new colors
$yellow: #ffe500;
$orange: #ff7a01;
$gradient-yellow-orange: linear-gradient($yellow, $orange);

$btn-pink: #ff89d6;
$btn-purple: #c01f9e;
$btn-light-pink: #ff89d6;
$btn-lightest-pink: #fefafd;

$gradient-btn-pink-purple: linear-gradient(
  -180deg,
  $btn-pink 0%,
  $btn-purple 100%
);

$gradient-btn-span: linear-gradient(
  0deg,
  $btn-light-pink 0%,
  $btn-lightest-pink 100%
);

$gradient-btn-red: linear-gradient(-180deg, $light-red 0%, $red 100%);
$gradient-btn-green: linear-gradient(-180deg, $light-green 0%, $green 100%);

$gradient-btn-span: linear-gradient(
  0deg,
  $btn-light-pink 0%,
  $btn-lightest-pink 100%
);

/**
 * ----------------------------------------
 * Exports
 * ----------------------------------------
 */

:export {
  pink: $pink;
  purple: $purple;
  light-purple: $light-purple;
  gray: $gray;
  dark-gray: $dark-gray;
  light-gray: $light-gray;
  light-blue: $light-blue;
}
