@import '../../theme/core.scss';

.locationCard {
  background-color: #fff;
  padding: 16px;
  width: 100%;
  // height: 200px;
  border-radius: 28px;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftComponent {
  flex: 2;
}

.rightComponent {
  text-align: center;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.locationName {
  font-family: 'GamerStation';
  font-size: 3vh;
  color: #953737;
  margin-bottom: 8px;
}

.capitalLetters {
  text-transform: uppercase;
  letter-spacing: 0.01px;
  font-size: '2vh';
  font-family: 'BebasNeue';
  color: #7f4799;
}

.grayText {
  color: #953737;
  font-size: 10px;
  margin-top: 4px;
}
.disabledText {
  color: gray;
  font-size: 10px;
  margin-top: 4px;
}
.rightSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.card {
  background-color: #fff;
  padding: 16px;
  width: 100%;
  // height: 200px;
  border-radius: 15px;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cardTitle {
  font-family: 'GamerStation';
  color: $yellow;
  -webkit-text-stroke: 2px purple;
  font-size: 4vh;
  text-align: center;
}
.cardDescription {
  font-family: 'BebasNeue';
  color: $orange;
}
.modal-screen{
  top:0;
  left:0
}