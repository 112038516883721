@import '../../theme/core.scss';

.wrapper {
  // background-color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  display: flex;
  flex-direction: column;

  // background-image: url(../../assets/images/imageBackground.jpg);
}

.nowin {
  font-family: 'GamerStation';
  color: $yellow;
  -webkit-text-stroke: 2px purple;
  font-size: 6vh;
}

.modalBackground {
  background-image: url(../../assets/images/Sunburst.png), $gradient-pink-purple;
}