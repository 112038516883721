@import '../../theme/core.scss';

.wrapper {
  // background-color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  display: flex;
  flex-direction: column;

  // background-image: url(../../assets/images/imageBackground.jpg);
}

.modalBackground {
  background-image: url(../../assets/images/Sunburst.png), $gradient-pink-purple;
}

.title {
  font-family: 'GamerStation';
  color: $yellow;
  -webkit-text-stroke: 2px purple;
  font-size: 5vh;
  text-align: center;
}
.subtitle {
  font-family: 'BebasNeue';
  color: #fff;
  text-align: center;
  letter-spacing: 0.5px;
}
.bottle {
  height: 50vh;
  z-index: 1;
}
