@import '../../theme/core.scss';

.root {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $dark-gray;
  height: '100vh';
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middleContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.sideContainer {
  flex: 1;
}

.menuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}

.topRow {}

.middleRow {}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fee400;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edd500;
}

.tabs {
  display: flex;
  justify-content: space-between;
  border: 1px solid white;
  padding: 5px;
  border-radius: 50px;
  margin: 0 16px;
  background: #c161b8;
}

.tabs button {
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  font-family: 'BebasNeue';
  // transition: all 0.5s ease-out;
}

.tabs button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50px;
}

.tabs button:disabled {
  // background: linear-gradient(245.39deg, #fc65ed -35.14%, #0500ff 143.06%);

  cursor: pointer;
  position: relative;
  // padding: 8px 16px;
  border-radius: 50px;
  // font-size: 3vh;
  border: 2px solid $orange;
  background-image: $gradient-yellow-orange;
  box-shadow: 0 3px 6px #ff7a017d, 0 3px 6px #c01f9e85;
  width: 100%;
  color: white;
  opacity: 1;
  // font-family: 'BebasNeue';
}

.content {
  padding: 16px;
  // background-color: white;
  font-weight: 300;
  line-height: 30px;
  font-size: 16px;
  text-align: justify;
  flex: 1;
  overflow-y: auto;
}