@import '../../theme/core.scss';

.colorCardWrapper {
  border-radius: 16px;
  //   flex: 2 1 0%;
  padding: 3px;
  display: flex;
  background-image: linear-gradient(rgb(255, 229, 0), rgb(255, 122, 1));
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.colorCard {
  padding: 8%;
  border-radius: 13px;
  background-size: cover;
  background-position: center center;
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #fff;
}

.colorCardWrapper:active {
  transform: translateY(3px) !important;
}
