@import '../../theme/core.scss';

.wrapper {
  // background-color: red;
  // width: 100%;
  aspect-ratio: 8/16;
  background-size: cover;
  height: 100%;
  position: absolute;
  z-index: 10000;
  display: flex;
  flex-direction: column;
// top:0;left:0
  // background-image: url(../../assets/images/imageBackground.jpg);
}

.modalBackground {
  background-image: url(../../assets/images/Sunburst.png), $gradient-pink-purple;
}
