@import '../../theme/core.scss';

.wrapper {
    // background-color: red;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
    display: flex;
    flex-direction: column;

    // background-image: url(../../assets/images/imageBackground.jpg);
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    // background-color: #2196F3;
    // height: 1px;
    // padding: 10px;
}



.gridItem {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-family: 'BebasNeue';
    font-size: 3vh;
}