@import '../../theme/core.scss';

.wrapper {
  // background-color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  display: flex;
  flex-direction: column;

  // background-image: url(../../assets/images/imageBackground.jpg);
}

.title {
  -webkit-text-stroke: 1px #7e0d6c;
  /* width and color */

  color: $yellow;
  font-family: 'GamerStation';
  font-size: 6vh;
}
.subtitle {
  //   -webkit-text-stroke: 1px #7e0d6c;
  /* width and color */

  color: $yellow;
  font-family: 'BebasNeue';
  font-size: 4vh;
  letter-spacing: 0.5px;
}

.modalBackground {
  background-image: url(../../assets/images/Sunburst.png), $gradient-pink-purple;
}
