@import '../../theme/core.scss';

.btn-black-fill {
  @include button-fill($dark-gray);
}

// .btn-pink-fill {
//   @include button-fill($pink);
// }

.btn-purple-fill {
  @include button-fill($purple);
}

.btn-black-outline {
  @include button-outline($dark-gray);
}

.btn-purple-outline {
  @include button-outline($purple);
}

.btn-pink {
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
  border-radius: 3.75rem;
  font-size: 3vh;
  border: 2px solid $orange;
  background-image: $gradient-btn-pink-purple;
  box-shadow: 0 3px 6px #ff7a017d, 0 3px 6px #c01f9e85;
  width: 100%;
  height: 50px;
}

.btn-pink span,
.btn-red span,
.btn-green span,
.btn-yellow span {
  color: white;
  background-image: $gradient-btn-span;
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1));
  font-family: 'GamerStation';
}

.btn-pink::before,
.btn-red::before,
.btn-green::before .btn-yellow::before {
  content: '';
  display: block;
  height: 0.1rem;
  position: absolute;
  top: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;
  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.btn-pink::after,
.btn-red::after,
.btn-green::after,
.btn-yellow::after {
  content: '';
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;
  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.btn-pink:active,
.btn-red:active,
.btn-green:active,
.btn-yellow:active {
  transform: scale(0.95);
}

.btn-red {
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
  border-radius: 3.75rem;
  font-size: 3vh;
  line-height: 2vh;
  border: 2px solid $orange;
  background-image: $gradient-btn-red;
  box-shadow: 0 3px 6px #ff7a017d, 0 3px 6px #c01f9e85;
  width: 100%;
  height: 50px;
}
.btn-green {
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
  border-radius: 3.75rem;
  font-size: 3vh;
  line-height: 2vh;
  border: 2px solid $orange;
  background-image: $gradient-btn-green;
  box-shadow: 0 3px 6px #ff7a017d, 0 3px 6px #c01f9e85;
  width: 100%;
  height: 50px;
}
.btn-yellow {
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
  border-radius: 3.75rem;
  font-size: 3vh;
  line-height: 2vh;
  border: 2px solid $orange;
  background-image: $gradient-yellow-orange;
  box-shadow: 0 3px 6px #ff7a017d, 0 3px 6px #c01f9e85;
  width: 100%;
  height: 50px;
}
