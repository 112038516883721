@import '../../../theme/core.scss';

.root {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $dark-gray;
  height: '100vh';
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middleContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sideContainer {
  flex: 1;
}

.menuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}

.topRow {
}

.middleRow {
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fee400;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edd500;
}

.input {
  border-radius: 50px !important;
  font-size: rem(16);
  padding-left: 30px;
  height: unset !important;
  // background-color: #9d7fe3 !important;
  color: #fff;

  &::placeholder {
    text-overflow: ellipsis !important;
    font-size: rem(14);
  }
}

.react-datepicker-wrapper {
  flex: 1 !important;
}

.dropDown > div {
  width: 100%;
  background: #fff;
  border-radius: 50px;
}

.dropDown > div > div {
  border: none;
  padding: 0.375rem 0.75rem;
  height: 45px;
}
