@import '../../theme/core.scss';
// https://codepen.io/josetxu/pen/Poxwpme

.checkboxWrapper {
    position: relative;
}

.toggle {
    left: 0;
    position: absolute;
    width: 50px;
    height: 25px;
    font-size: var(--font-size);
    margin: 0;
    filter: opacity(0);
    cursor: pointer;
    z-index: 2;
}

.toggle~.switch {
    position: absolute;
    width: 50px;
    height: 25px;
    padding: 3px;
    font-size: var(--font-size);
    background: linear-gradient(rgb(255, 229, 0), rgb(255, 122, 1));
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle~.switch .inner {
    width: 100%;
    height: 100%;
    background: linear-gradient(dimgray, silver);
    border-radius: 53px;
    box-shadow: inset 0 0 1.5em rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

.toggle~.switch .inner .disc {
    aspect-ratio: 1;
    height: 100%;
    background: linear-gradient(to top, silver, whitesmoke);
    border-radius: 50%;
    box-shadow: 0 0.4em 0.6em rgba(0, 0, 0, 0.2);
    position: relative;
    left: -30%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.toggle~.switch .inner .disc::before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    background: linear-gradient(silver, whitesmoke);
    border-radius: 50%;
    text-align: center;
    // line-height: calc(3.5em * 0.8);
    font-family: sans-serif;
    color: gray;
}

.toggle:checked~.switch .inner {
    background: linear-gradient(green, limegreen);
}

.toggle:checked~.switch .inner .disc {
    left: 30%;
}

.toggle:checked~.switch .inner .disc::before {
    content: '';
    color: limegreen;
}