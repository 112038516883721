/**
 * ----------------------------------------
 * Variables
 * ----------------------------------------
 */

$roboto: 'Roboto';
$open-san: 'Open Sans';

/**
 * ----------------------------------------
 * Exports
 * ----------------------------------------
 */

:export {
  roboto: $roboto;
  open-san: $open-san;
}


@font-face {
  font-family: 'GamerStation';
  src: local('GamerStation'), url(../assets/fonts/GamerStation.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'BebasNeue';
  src: local('BebasNeue'), url(../assets/fonts/BebasNeue-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}